<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-user-list'"
        :title="'Edit user'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.firstName"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('First name')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.lastName"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Last name')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.email"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('login.username')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.phone"
                dense
                outlined
                :label="$t('Phone')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.password"
                type="password"
                dense
                outlined
                :label="$t('Password')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.passwordConfirmation"
                type="password"
                dense
                outlined
                :label="$t('Password confirmation')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.userType"
                :rules="[validator.required]"
                :items="userTypeOptions"
                :item-text="item => $t(item.name)"
                item-value="key"
                :disabled="user.email === 'office@sistemcd.rs' || currentUser.role !== 'super_admin'"
                :label="$t('Type')"
                dense
                outlined
                class="w-full"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.status"
                :rules="[validator.required]"
                :items="statusOptions"
                :disabled="(user.userType === 'super_admin' && currentUser.email !== 'office@sistemcd.rs') || user.email === 'office@sistemcd.rs' || currentUser.role === 'user'"
                :item-text="item => $t(item.name)"
                item-value="key"
                :label="$t('Status')"
                dense
                outlined
                class="w-full"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.location"
                :rules="[validator.required]"
                :items="$store.getters.getStocks"
                dense
                outlined
                class="w-full mb-1"
                :label="$t('Location')"
                hide-details
                item-value="id"
                item-text="name"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col lg="2">
              <v-autocomplete
                v-model="itemsPerPage"
                :label="$t('Items per page')"
                type="number"
                dense
                hide-details
                outlined
                :items="[10, 20, 50, 100]"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-user-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const currentUser = JSON.parse(localStorage.getItem('userData'))
    const snackbarMessage = ''
    const userId = null
    const snackbar = false
    const loading = true
    const options = {}
    const user = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      correspondenceEmail: '',
      phone: '',
      mobile: '',
      language: '',
      correspondenceLanguage: '',
      title: '',
      function: '',
      password: '',
      passwordConfirmation: '',
      userType: '',
      placementAddress: '',
      status: '',
      twoFactorVerificationMethod: '',
      company: '',
    }
    const itemsPerPage = localStorage.getItem('itemsPerPage') ? +localStorage.getItem('itemsPerPage') : 10
    const statusFilter = ''
    const statusOptions = this.$store.state.baseStatuses
    const languageOptions = this.$store.state.languages
    const userTypeOptions = this.$store.state.userTypes

    const companies = []

    return {
      itemsPerPage,
      snackbarMessage,
      userTypeOptions,
      languageOptions,
      companies,
      userId,
      snackbar,
      options,
      loading,
      statusFilter,
      user,
      statusOptions,
      currentUser,

      icons: {
        mdiCellphoneKey,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      axiosIns.get(`/admin/users/${this.$route.params.id}`)
        .then(res => {
          const { user } = res.data

          this.userId = user.id
          this.user.firstName = user.first_name
          this.user.lastName = user.last_name
          this.user.email = user.email
          this.user.phone = user.phone
          this.user.mobile = user.mobile
          this.user.userType = user.user_type
          this.user.status = user.status
          this.user.twoFactorVerificationMethod = user.two_factor_verification_method
          this.user.location = user.location
        })
        .catch()
    },
    save() {
      if (this.$refs.form.validate()) {
        localStorage.setItem('itemsPerPage', `${this.itemsPerPage}`)
        const user = {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          email: this.user.email,
          correspondence_email: this.user.correspondenceEmail,
          phone: this.user.phone,
          mobile: this.user.mobile,
          language: this.user.language,
          correspondence_language: this.user.correspondenceLanguage,
          title: this.user.title,
          function: this.user.function,
          password: this.user.password,
          password_confirmation: this.user.passwordConfirmation,
          user_type: this.user.userType,
          placement_address: this.user.placementAddress,
          status: this.user.status,
          two_factor_verification_method: this.user.twoFactorVerificationMethod,
          company_id: this.user.company,
          location: this.user.location,
        }

        axiosIns({
          method: this.userId ? 'PUT' : 'POST',
          data: user,
          url: this.userId ? `/admin/users/${this.userId}` : '/admin/users',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.userId = res.data.user.id
            const userData = JSON.parse(localStorage.getItem('userData'))
            if (this.userId === userData.id) {
              axiosIns.get('/auth/userInfo')
                .then(response => {
                  const { ...userInfo } = response.data
                  localStorage.setItem('userData', JSON.stringify(userInfo.user))
                })
            }
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },

    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?filter=${filter}`)
          .then(res => {
            this.companies = res.data.companies.data
          })
          .catch()
      }
    },
  },
}
</script>

<style lang="scss">
</style>
